import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="hero-teaser"]');

  const { default: HeroTeaser } = await import(
    /* webpackChunkName: "hero-teaser" */ './hero-teaser'
  );

  forEach(elements, (index, element) => new HeroTeaser(element));
}
