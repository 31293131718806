export default function buildThresholdList(steps = 20) {
  let thresholds = [];

  for (let i = 1.0; i <= steps; i++) {
    let ratio = i / steps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
}
