import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="teaser"]');

  if (elements.length) {
    const { default: Teaser } = await import(/* webpackChunkName: "teaser" */ './teaser');

    forEach(elements, (_, element) => {
      if (element.getAttribute('data-js-module-initialized') === 'true') return;
      new Teaser(element);
    });
  }
}
