import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import throttle from 'lodash.throttle';
import { animate } from 'motion';

import BaseModule from '../../../javascripts/helpers/baseModule';
import triggerCustomEvent from '../../../javascripts/events/triggerCustom';
import buildThresholdList from '../../../javascripts/helpers/buildThresholdList';

export default class Navigation extends BaseModule {
  constructor(element) {
    super(element, element.getAttribute('data-js-module'));

    this.content = document.getElementById('content');
    this.stageId = this.elements.self.getAttribute('data-stage-id');
    this.isHidden = true;
    this.initialAnimate = false;

    this.focusedElementBeforeOpen = document.activeElement;

    this.getElements([
      { id: 'trigger', selector: 'trigger' },
      { id: 'logo', selector: 'logo' },
      { id: 'mainStage', selector: 'main-stage' },
      { id: 'overlay', selector: 'overlay' }
    ]);

    this.updateLogo(window.scrollY > window.innerHeight / 3);
    this.updateTriggerIcon(this.isHidden);
    this.initEventListeners();
    this.initContentObserver();
  }

  initEventListeners() {
    this.elements.trigger.addEventListener('click', () => this.toggleOverlay());

    window.addEventListener(
      'scroll',
      throttle(() => {
        if (!this.isHidden) return;
        this.updateLogo(window.scrollY > window.innerHeight / 3);
      }),
      200
    );

    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        if (this.isHidden) return;
        this.toggleOverlay(false);
      }
    });
  }

  updateTriggerIcon(isHidden) {
    const icon = this.elements.trigger.querySelector('use');
    const burger = window.innerWidth < 768 ? '#icon-mobile-burger' : '#icon-burger';
    if (icon) icon.setAttribute('xlink:href', isHidden ? burger : '#icon-close');
  }

  updateLogo(isVisible) {
    this.elements.logo.setAttribute('aria-hidden', !isVisible);
    this.elements.logo.setAttribute('tabindex', isVisible ? '0' : '-1');
  }

  toggleOverlay() {
    this.isHidden = !this.isHidden;

    this.updateTriggerIcon(this.isHidden);

    this.elements.overlay.setAttribute('aria-hidden', this.isHidden);
    this.elements.self.setAttribute('data-navigation-open', !this.isHidden);
    document.body.setAttribute('data-navigation-open', !this.isHidden);

    if (this.isHidden) {
      this.updateLogo(false);
      this.closeOverlay();
    } else {
      this.updateLogo(true);
      this.showOverlay();
    }
  }

  showOverlay() {
    const { overlay, mainStage } = this.elements;

    disableBodyScroll(mainStage, { reserveScrollBarGap: true });

    overlay.classList.add('is-visible');
    animate(overlay, { y: ['-100%', '0%'] }, { easing: 'ease-in-out', duration: 0.6 });

    if (!this.initialAnimate) {
      setTimeout(() => triggerCustomEvent(window, `MainStage.${this.stageId}.animateIn`), 400);
      this.initialAnimate = true;
    }
  }

  closeOverlay() {
    const { overlay, mainStage } = this.elements;

    animate(overlay, { y: ['0%', '-100%'] }, { easing: 'ease-in-out', duration: 0.6 });
    triggerCustomEvent(window, `MainStage.${this.stageId}.closeGroups`);

    enableBodyScroll(mainStage);
    this.focusedElementBeforeOpen.focus();

    setTimeout(() => {
      overlay.classList.remove('is-visible');
      mainStage.scrollTo({ top: 0 });
    }, 600);
  }

  initContentObserver() {
    const { self } = this.elements;

    if (!this.content) {
      return self.classList.add('is-visible');
    }

    const intersectionObserver = new IntersectionObserver(
      ([entry]) => {
        if (!this.isHidden) return;
        if (entry && entry.boundingClientRect.y < 100) self.classList.add('is-visible');
        else self.classList.remove('is-visible');
      },
      { threshold: buildThresholdList(100) }
    );

    intersectionObserver.observe(this.content);
  }
}
