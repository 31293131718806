export default async function init() {
  const forms = document.querySelectorAll('[data-js-module="form"]');

  const initForm = async () => {
    const { default: Form } = await import(
      /* webpackChunkName: "form" */ './form'
    );
    forms.forEach((form) => new Form(form));
  };

  if (forms.length) initForm();
}
