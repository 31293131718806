import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="main-stage"]');

  if (elements.length) {
    const { default: MainStage } = await import(/* webpackChunkName: "main-stage" */ './main-stage');

    forEach(elements, (_, element) => {
      if (element.getAttribute('data-js-module-initialized') === 'true') return;
      new MainStage(element);
    });
  }
}
