export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="standard-teasers"]');

  if (elements.length) {
    const { default: StandardTeasers } = await import(
      /* webpackChunkName: "standard-teasers" */ './StandardTeasers.jsx'
    );

    elements.forEach((element) => {
      new StandardTeasers(element);
    });
  }
}
