import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="indicator"]');

  const intersectionObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.setAttribute('data-animated', 'true');
          observer.unobserve(entry.target);
        }
      });
    },
    {
      threshold: 1
    }
  );

  forEach(elements, (_, element) => intersectionObserver.observe(element));
}
