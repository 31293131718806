import throttle from 'lodash.throttle';

export default class PageLine {
  constructor(elements) {
    this.elements = Array.from(elements);
    this.currentIndex = -1;
    this.shouldNotAnimate = Boolean(document.querySelector("[data-page-line-animate='false']"));

    this.bindEventListeners();

    if (!this.shouldNotAnimate) {
      this.checkInitialActiveAnimations();
    }
  }

  isInViewport(element) {
    const { y, height } = element.getBoundingClientRect();
    const windowHeight = (window.innerHeight / 3) * 2;

    if (height <= windowHeight) return y <= window.innerHeight;
    return y < windowHeight;
  }

  checkInitialActiveAnimations() {
    const animations = this.elements.filter((element) => {
      const { height } = element.getBoundingClientRect();
      element.style = `--duration: ${(height * 2) / 1000}s;`;

      return this.isInViewport(element);
    });

    animations.forEach((element, index) => element.setAttribute('data-animated', animations.length - 1 === index));

    this.currentIndex = animations[animations.length - 1];
  }

  checkCurrentActiveAnimation() {
    const element = this.elements[this.currentIndex + 1];

    if (element && this.isInViewport(element)) {
      element.setAttribute('data-animated', true);
    }
  }

  bindEventListeners() {
    window.addEventListener(
      'scroll',
      throttle(() => this.checkCurrentActiveAnimation(), 50)
    );

    window.addEventListener('PageLine.animateIn', () => this.checkInitialActiveAnimations());

    this.elements.forEach((element, index) => {
      element.addEventListener('animationend', () => {
        this.currentIndex = index;
        this.checkCurrentActiveAnimation();
      });
    });
  }
}
