import '../stylesheets/main.scss';

// Use what-input to track input method
import 'what-input';

import domready from 'domready';

// Shims
import forEach from './dom/forEach';

import initImage from '../components/image/javascripts';
import '../components/icon/javascripts';

import initAccordion from '../components/accordion/javascripts';
import initHeroTeaser from '../components/hero-teaser/javascripts';
import initFileUpload from '../components/file-upload/javascripts';
import initForm from '../components/form/javascripts';
import initTeaser from '../components/teaser/javascripts';
import initPageLine from '../components/page-line/javascripts';
import initMainStage from '../components/main-stage/javascripts';
import initGlossaryPopup from '../components/glossary-popup/javascripts';
import initNavigation from '../components/navigation/javascripts';
import initIntroAnimation from '../components/intro-animation/javascripts';
import initGallery from '../components/gallery/javascripts';
import initVideo from '../components/video/javascripts';
import initIndicator from '../components/indicator/javascripts';
import initStandardTeasers from '../components/standard-teasers/javascripts';
import initBubbleBox from '../components/bubble-box/javascripts';

function enhancedDomReady(init) {
  domready(init);
  window.addEventListener('reload', init);
}

enhancedDomReady(() => {
  initNavigation();
  initPageLine();
  initImage();
  initMainStage();
  initStandardTeasers();
  initTeaser();
  initAccordion();
  initHeroTeaser();
  initFileUpload();
  initForm();
  initIndicator();
  initVideo();
  initGallery();
  initGlossaryPopup();
  initBubbleBox();
  initIntroAnimation();
});

// All links with target="_blank" attribute should have an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
forEach(document.querySelectorAll("[target='_blank']"), (index, link) => {
  link.setAttribute('rel', 'noopener');
});
