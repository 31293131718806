import forEach from '../../../javascripts/dom/forEach';

export default function init() {
  const lazyImages = document.querySelectorAll('[loading="lazy"]');

  const initLazyLoadingPolyfill = async () => {
    await import(/* webpackChunkName: "image--lazysizes" */ 'lazysizes');
  };

  /**
   * Native lazy loading
   * @see {@link https://web.dev/native-lazy-loading/}
   * @see {@link https://obj.fork.de/pages/viewpage.action?pageId=63274039}
   * @see {@link https://caniuse.com/loading-lazy-attr}
   * @returns {void}
   */
  function initNativeLazyLoading() {
    if ('loading' in HTMLImageElement.prototype) {
      forEach(lazyImages, (_, img) => {
        if (!img.src && img.dataset.src) img.src = img.dataset.src;
        if (!img.srcset && img.dataset.srcset) img.srcset = img.dataset.srcset;
      });
    } else {
      initLazyLoadingPolyfill();
    }
  }

  if (lazyImages.length) initNativeLazyLoading();
}
