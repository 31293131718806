import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="bubble-box"]');

  const { default: BubbleBox } = await import(
    /* webpackChunkName: "bubble-box" */ './bubble-box'
  );

  forEach(elements, (index, element) => new BubbleBox(element));
}
