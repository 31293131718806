import { createPopper } from '@popperjs/core';

export default class GlossaryPopup {
  constructor(elements) {
    this.elements = Array.from(elements);
    this.ids = new Set();
    elements.forEach((element) => {
      const id = element.getAttribute('data-glossary-id');
      this.ids.add(id);
    });

    this.fetchGlossary();
    this.initEventListeners();
  }

  createPopup(item, link) {
    const filterItems = this.elements.filter((element) => {
      return item.id === parseInt(element.getAttribute('data-glossary-id'), 10);
    });

    filterItems.forEach((element) => {
      // We need to check the last element to prevent double initialization
      const nextElement = element.nextElementSibling;

      if (nextElement && nextElement.classList.contains('glossary-popup')) return;
      const popup = document.createElement('div');
      popup.classList.add('glossary-popup');
      popup.innerHTML = `
        <p class="text">${item.title}</p>
        ${item.description || ''}
        <a class="link" href="${link.url}">${link.label}</a>
      `;

      element.after(popup);
      createPopper(element.querySelector('span'), popup, {
        placement: 'top-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -21]
            }
          }
        ]
      });
    });
  }

  initEventListeners() {
    this.elements.forEach((element) => {
      element.addEventListener('pointerover', () => {
        const activePopup = element.nextElementSibling;
        if (!activePopup) return;
        activePopup.classList.add('visible');
      });

      element.addEventListener('pointerout', () => {
        const activePopup = element.nextElementSibling;
        if (!activePopup) return;
        activePopup.classList.remove('visible');
      });

      element.addEventListener('focus', () => {
        const activePopup = element.nextElementSibling;
        if (!activePopup) return;
        activePopup.classList.add('visible');
      });

      element.addEventListener('blur', () => {
        const activePopup = element.nextElementSibling;
        if (!activePopup) return;
        activePopup.classList.remove('visible');
      });
    });
  }

  async fetchGlossary() {
    try {
      const response = await fetch('/api/glossary', {
        method: 'POST',
        headers: {
          accept: 'application/json'
        },
        body: JSON.stringify({
          ids: [...this.ids]
        })
      });
      const data = await response.json();

      data.items.forEach((item) => {
        this.createPopup(item, data.link);
      });
    } catch (error) {
      console.error(error);
    }
  }
}
